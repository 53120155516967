<template>
  <div>
    <div class="area">
      <ul class="circles">
        <li><i class="i-Administrator"></i></li>
        <li><i class="i-Administrator"></i></li>
        <li><i class="i-Address-Book"></i></li>
        <li><i class="i-Business-ManWoman"></i></li>
        <li><i class="i-Repeat2"></i></li>
        <li><i class="i-Shopping-Bag"></i></li>
        <li><i class="i-Dec"></i></li>
        <li><i class="i-Check"></i></li>
        <li><i class="i-Cash-register-2"></i></li>
        <li><i class="i-File-Clipboard-File--Text"></i></li>
      </ul>
    </div>

    <div class="container-wrapper">
      <div
          id="container"
          :class="{ 'right-panel-active': !show.loginClient }"
          class="form-container-wrapper"
      >
        <div class="form-container staff-container">
          <b-form id="staff" @submit.prevent="authenticate">
            <h2 class="mb-30">Introducer Sign In</h2>
            <div class="social-container"></div>

            <b-input-group class="mb-20">
              <b-input
                  v-model="login.email"
                  placeholder="Email Address"
                  size="lg"
                  :state="loginError ? false : null"
                  @change="validateEmail(login.email, 'email-introducer')"
              />
              <template #append>
                <b-input-group-text
                    id="email-introducer"
                    class="bg-primary"
                    :class="{'bg-danger' : loginError}"
                ><i class="text-20 text-white i-Email"></i>
                </b-input-group-text>
              </template>
            </b-input-group>

            <b-input-group>
              <b-input
                  v-model="login.password"
                  placeholder="Password"
                  size="lg"
                  type="password"
                  :state="loginError ? false : null"
              />
              <template #append>
                <b-input-group-text
                    :class="{ 'bg-success': login.password, 'bg-danger' : loginError }"
                    class="bg-primary"
                ><i class="text-20 text-white i-Key"></i>
                </b-input-group-text>
              </template>
            </b-input-group>

            <b-button
                :class="{ show: login.password && login.email }"
                :disabled="loading"
                class="btn-block btn-submit mt-30 w-75"
                size="lg"
                tag="button"
                type="submit"
                variant="primary ripple"
            >Sign-In
            </b-button>

              <b-link
                      class="mt-3"
                      href="#foo"
                      @click="passwordResetRequest"
              >Forgotten Password
              </b-link
              >

            <div v-if="loading" v-once class="typo__p">
              <div class="spinner sm spinner-primary mt-3"></div>
            </div>
          </b-form>
        </div>

        <div class="form-container client-container">
          <b-form id="client" @submit.prevent="authenticate">
            <h2 class="mb-30">Client Sign In</h2>

            <div v-if="$store.getters.coupon" class="social-container">
              <p>Coupon: {{ $store.getters.coupon }}</p>
            </div>

            <b-input-group class="mb-20">
              <b-input
                  v-model="login.email"
                  placeholder="Email Address"
                  size="lg"
                  :state="loginError ? false : null"
                  @change="validateEmail(login.email, 'email-client')"
              />
              <template #append>
                <b-input-group-text
                    id="email-client"
                    class="bg-primary"
                    :class="{'bg-danger' : loginError}"
                >
                  <i class="text-20 text-white i-Email"></i>
                </b-input-group-text>
              </template>
            </b-input-group>

            <b-input-group>
              <b-input
                  v-model="login.password"
                  placeholder="Password"
                  size="lg"
                  type="password"
                  :state="loginError ? false : null"
              />
              <template #append>
                <b-input-group-text
                    :class="{ 'bg-success': login.password, 'bg-danger' : loginError }"
                    class="bg-primary"
                ><i class="text-20 text-white i-Key"></i>
                </b-input-group-text>
              </template>
            </b-input-group>

            <b-button
                id="submit"
                :disabled="loading"
                class="btn-block btn-submit mt-30 w-75"
                size="lg"
                tag="button"
                type="submit"
                variant="primary ripple"
            >Sign-In
            </b-button>

              <b-link
                      class="mt-3"
                      href="#foo"
                      @click="passwordResetRequest"
              >Forgotten Password
              </b-link
              >

            <div v-if="loading" v-once class="typo__p">
              <div class="spinner sm spinner-primary mt-3"></div>
            </div>
          </b-form>
        </div>

        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-panel overlay-left">
              <img :src="logo" alt="logo"/>
              <h1 class="text-white pb-20">Are you a client?</h1>
              <b-button size="lg" variant="outline-light" @click="signInClient"
              >Click Here
              </b-button>
            </div>

            <div class="overlay-panel overlay-right">
              <img :src="logo" alt="logo"/>
              <h1 class="text-white pb-20">Are you an introducer?</h1>
              <b-button
                  size="lg"
                  variant="outline-light"
                  @click="signInIntroducer"
              >Click Here
              </b-button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toast} from "@/mixins/toast";
import {emailHelpers} from "@/mixins/emailHelpers";
import {http} from "@/services";

export default {
  name: "Login",
  mixins: [toast, emailHelpers],
  async created() {
    if (this.$route.params.coupon) {
      // used for applying coupon on login (user with a coupon has been redirected to login as they already have a user account)
      // coupon then needs to be handled at API endpoint
    } else if (this.$route.params.access && this.$route.params.refresh) {
      var data = {
        access: this.$route.params.access,
        refresh: this.$route.params.refresh,
        noRedirect: true
      };
      await this.$store.dispatch("_setTokens", data);

      // very specific use case redirect to specific page with access codes
      let redirectUrl = null
      if (this.$route.params?.section && this.$route.params?.area && this.$route.params?.id) {
        redirectUrl = `/${this.$route.params.area}/${this.$route.params.section}/${this.$route.params.id}`
      }
      this.$nextTick(() => {
        this.$store.dispatch('redirectUser', redirectUrl)
      })
    }
  },
  watch: {
    login: {
      immediate: true,
      deep: true,
      handler() {
        if (this.login.email && this.login.password) {
          let element = document.getElementById("submit");
          element.classList.add("show");
        }
      },
    },
    loginError: {
      immediate: false,
      deep: true,
      handler(value) {
        if (value && value.detail) {
          this.toast(value.detail, "Error", "danger");
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      login: {
        email: "",
        password: "",
      },
      logo: require("@/assets/images/logo2.png"),
      show: {
        loginClient: this.$route.name === "LoginClient",
        coupon: this.$store.getters.coupon
      },
    };
  },
  computed: {
    loginError() {
      return this.$store.getters.loginError;
    },
  },
  methods: {
    async authenticate() {
      console.log('authenticate')
      if (this.login.email && this.login.password) {
        console.log('has login email and password')
        this.$store.commit("loginError", null);

        console.log('params', this.$route.params)

        await this.$store.dispatch("obtainToken", {
          email: this.login.email,
          password: this.login.password
        });

        if (this.$route.params.coupon) {
          this.applyCoupon()
        }
      }
    },
    applyCoupon() {
      if (!this.$route.params.coupon) return
      // handle coupon login manually
      console.log('now set coupons ')
      let data = {
        coupon: this.$route.params.coupon
      }
      http.patch('coupon_apply', data).then(
          response => {
            // set toast message for page load when redirected
            // re-direct user to transaction with coupon
            let messages = []
            if (response.data?.action === 'COUPON_APPLIED') {
              messages.push({state: 'success', message: response.data.message})
              this.$router
                  .push({
                    name: 'ClientTransactionCouponDetail',
                    params: {id: response.data.coupon.transaction}
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            }
            if (response.data?.action === 'ERROR') {
              messages.push({state: 'error', message: response.data.message})
              this.$router
                  .push({
                    name: 'ClientDashboard',
                    params: {}
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            }
            this.$store.commit('toastMessages', messages)
          }
      ).catch(
          error => {
            console.log(error.response.data)
          }
      )

    },
    signInClient() {
      this.show.loginClient = true;
    },
    signInIntroducer() {
      this.show.loginClient = false;
    },
    passwordResetRequest() {
      window.open(this.$store.getters.endpoints.passwordReset)
    },
  },
};
</script>
