export const emailHelpers = {
    methods: {
        validateEmail(email, elementId) {
            let element = document.getElementById(elementId);
            if (
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                    email
                )
            ) {

                element.classList.add('bg-success');
            } else {
                element.classList.remove('bg-success');
            }
        }
    }
}
