export const toast = {
    methods: {
        pageLoadToastMessage () {
            // page has loaded display queued toast messages
            if (this.$store.getters.toastMessages.length) {
              while (this.$store.getters.toastMessages.length) {
                    let toast = this.$store.getters.toastMessages.pop()
                    if (toast.state === 'error') {
                        this.toastError(toast.message)
                    } else {
                        this.toast(toast.message)
                    }
                }
            }
         },
        toast(
            message,
            title = 'Success',
            variant = 'primary',
            duration = 5000,
            position = 'b-toaster-top-right'
        ) {
            if (variant === 'danger') {
                duration = 10000;
                position = 'b-toaster-top-center';
            }
            // position was 'b-toaster-top-center'

            this.$bvToast.toast(message, {
                title: title,
                autoHideDelay: duration,
                appendToast: true,
                variant: variant,
                toaster: position
            });
        },
        toastError(
            message, title='Error'
        ) {
            this.toast(message, title, "danger");
        },
        async message(message, title = 'Complete', variant = 'success') {
            return this.$bvModal
                .msgBoxOk(message, {
                    title: title,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: variant,
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                .then((value) => {
                    return value;
                })
                .catch((error) => {
                    // An error occurred
                    console.log(error);
                });
        }
    }
};

export default toast;
